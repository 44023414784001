



































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import {
  apiGoodsPackgeLists,
  apiGoodsPackgeAdd,
  apiGoodsPackgeEdit,
  apiGoodsPackgeDelete,
  apiGoodsPackgeDetail,
  apiGoodsPackgeSwitchStatus,
  apiGoodsPackgeListsNopage,
} from "@/api/goods";
import { RequestPaging } from "@/utils/util";

@Component({
  components: {
    LsDialog,
    MaterialSelect,
    lsPagination,
  },
})
export default class OperationManagementIndex extends Vue {
  /** S Data **/
  identity: number | null = null; // 当前编辑用户的身份ID
  apiGoodsPackgeLists = apiGoodsPackgeLists;
  pager: RequestPaging = new RequestPaging();

  dialogVisible: boolean = false;
  build_form: object = {
    packge_name: "", //运营包名称
    packge_description: "", //运营包描述
    packge_img_pic: "", //缩略图
    status: 1, //状态
    packge_img_maxpic: "", //大图
    extend_ids: "", //关联id
  };

  listsNopage = [];

  searchObj: Object = {
    packge_name: "",
    create_time: "",
    status: "",
  };

  rules = {
    packge_name: [
      { required: true, message: "请输入运营包名称", trigger: "blur" },
    ],
    packge_description: [
      { required: true, message: "请输入运营包描述", trigger: "blur" },
    ],
    packge_img_pic: [
      { required: true, message: "请上传运营包缩略图", trigger: "blur" },
    ],
    packge_img_maxpic: [
      { required: true, message: "请上传运营包大图", trigger: "blur" },
    ],
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate((valid: boolean): any => {
      if (!valid) return;

      if (!this.identity) {
        this.goodsPackgeAdd();
      } else {
        this.goodsPackgeEdit();
      }
    });
  }

  // 新增商品包
  async goodsPackgeAdd(): Promise<void> {
    let obj: any = {
      ...this.build_form,
    };
    obj.extend_ids = obj.extend_ids.toString();
    const res = await apiGoodsPackgeAdd({ ...obj });

    if (res) {
      this.dialogVisible = false;
      this.goodsPackgeListsNopage("");
    }
  }

  // 商品包编辑
  async goodsPackgeEdit(): Promise<void> {
    let obj: any = {
      ...this.build_form,
    };
    obj.extend_ids = obj.extend_ids.toString();
    const res = await apiGoodsPackgeEdit({ ...obj, id: this.identity });

    if (res) {
      this.dialogVisible = false;
      this.getGoodsPackgeLists();
    }
  }

  // 商品包删除
  async goodsPackgeDelete(id: Number): Promise<void> {
    const res = await apiGoodsPackgeDelete({ id: id });
    if (res) {
      this.getGoodsPackgeLists();
    }
  }

  // 商品包切换状态
  async goodsPackgeSwitchStatus(id: number): Promise<void> {
    await apiGoodsPackgeSwitchStatus({ id });
  }

  // 获取商品包详情
  async goodsPackgeDetail(id: number): Promise<void> {
    this.goodsPackgeListsNopage("");
    const res = await apiGoodsPackgeDetail({ id });
    if (res) {
      this.identity = id;
      this.build_form = {
        packge_name: res.packge_name, //运营包名称
        packge_description: res.packge_description, //运营包描述
        packge_img_pic: res.packge_img_pic, //缩略图
        status: res.status, //状态
        packge_img_maxpic: res.packge_img_maxpic, //大图
        extend_ids: res.extend_ids ? res.extend_ids.split(",").map(Number) : "", //关联id
      };

      this.dialogVisible = true;
    }
  }

  //获取关联id列表
  async goodsPackgeListsNopage(query: string): Promise<void> {
    const res = await apiGoodsPackgeListsNopage({ packge_name: query });
    this.listsNopage = res;
  }

  build() {
    this.identity = null;
    this.build_form = {
      packge_name: "", //运营包名称
      packge_description: "", //运营包描述
      packge_img_pic: "", //缩略图
      status: 1, //状态
      packge_img_maxpic: "", //大图
      extend_ids: "", //关联id
    };
    this.dialogVisible = true;
    this.getGoodsPackgeLists();
  }
  // 获取运营包
  getGoodsPackgeLists(page?: number) {
    page && (this.pager.page = page);
    this.pager.request({
      callback: apiGoodsPackgeLists,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getGoodsPackgeLists(1);
  }

  created() {
    this.getGoodsPackgeLists();
  }
}
